/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "datauiRestAPI",
            "endpoint": "https://7qje9f1o33.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://gosgbsj3gfeubiukrtjqwieyma.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:389f01bf-d648-4929-afe0-783fa6cade51",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_JHCoM6IVN",
    "aws_user_pools_web_client_id": "4oqubhvt00ouhr8oef8l6ksjg7",
    "oauth": {
        "domain": "auth.ui.data.pl-x.cloud.auth.eu-central-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "DataUI_OverwriteArticle_ApprovalLUT",
            "region": "eu-central-1"
        },
        {
            "tableName": "DataUI_OverwriteArticle_MarketingMailLUT",
            "region": "eu-central-1"
        },
        {
            "tableName": "DataUI_OverwriteNGW_ApprovalLUT",
            "region": "eu-central-1"
        },
        {
            "tableName": "DataUI_OverwriteNGW_InfoMailLUT",
            "region": "eu-central-1"
        },
        {
            "tableName": "DataUI_OverwriteWKZ_MarketingMailLUT",
            "region": "eu-central-1"
        },
        {
            "tableName": "OverwriteArticle-2jfnwl6exvddxj2bowou2qnzdi-main",
            "region": "eu-central-1"
        },
        {
            "tableName": "OverwriteArticle-irkiwyrvhzep5fmsvwjbjh46ri-dev",
            "region": "eu-central-1"
        },
        {
            "tableName": "OverwriteNGW-2jfnwl6exvddxj2bowou2qnzdi-main",
            "region": "eu-central-1"
        },
        {
            "tableName": "OverwriteNGW-irkiwyrvhzep5fmsvwjbjh46ri-dev",
            "region": "eu-central-1"
        },
        {
            "tableName": "OverwriteWKZ-2jfnwl6exvddxj2bowou2qnzdi-main",
            "region": "eu-central-1"
        },
        {
            "tableName": "OverwriteWKZ-irkiwyrvhzep5fmsvwjbjh46ri-dev",
            "region": "eu-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "vnr-dataui-ngw-cache",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
